import React, { useState } from "react";
import "../css/NavbarNew.css";
import { Link } from "react-router-dom";


const NavbarNew = () => {
    const logo = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599933/rio_main_website/fjap8mgbuzuvmzixb0qq.png"
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  
    const handleToggleMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    };
  
    const handleMenuClick = () => {
      setIsMobileMenuOpen(false); // Close menu after clicking
      setIsServicesDropdownOpen(false);
    };

    const toggleServicesDropdown = () => {
        setIsServicesDropdownOpen(!isServicesDropdownOpen);
      };
    
  
    return (
      <nav className="navbar">
        <div className="navbar-logo">
            <Link to='/'>
            <img src={logo} alt='logo'/>
            </Link>
        </div>
        <div className={`navbar-links ${isMobileMenuOpen ? "active" : ""}`}>
          <ul>
            <li onClick={handleMenuClick}>
              <Link to="/">Home</Link>
            </li>
            <li onClick={handleMenuClick}>
              <Link to="/about">About</Link>
            </li>
            <li
              className="services-menu"
              onMouseEnter={() => setIsServicesDropdownOpen(true)}
              onMouseLeave={() => setIsServicesDropdownOpen(false)}
            >
              <Link to="/our-service">
                Services
                <span
                  className={`service_arrow ${
                    isServicesDropdownOpen ? "rotate-up" : "rotate-down"
                  }`}
                >
                  ▼
                </span>
              </Link>
              <ul className={`dropdown ${isServicesDropdownOpen ? "show" : ""}`}>
                <li onClick={handleMenuClick}>
                  <Link to="/our-service/staffing">Staffing</Link>
                </li>
                <li onClick={handleMenuClick}>
                  <Link to="/our-service/digital-marketing/">Digital Marketing</Link>
                </li>
                <li onClick={handleMenuClick}>
                  <Link to="/our-service/app-development">App Development</Link>
                </li>
                <li onClick={handleMenuClick}>
                  <Link to="/our-service/web-development">Web Development</Link>
                </li>
                <li onClick={handleMenuClick}>
                  <Link to="/our-service/odoo/">Odoo ERP</Link>
                </li>
                <li onClick={handleMenuClick}>
                  <Link to="/our-service/ipr/">Intellectual Property Rights</Link>
                </li>
              </ul>
            </li>
            <li onClick={handleMenuClick}>
              <Link to="/blog">Blogs</Link>
            </li>
          </ul>

          <div onClick={handleMenuClick} className="header_cb_1">
        <Link to='/contact'>
        <button className="get-started">Contact Us</button></Link>
        </div>
        </div>

        <div onClick={handleMenuClick} className="header_cb_2">
        <Link to='/contact'>
        <button className="get-started">Contact Us</button></Link>
        </div>
        <button className="menu-toggle" onClick={handleToggleMenu}>
          ☰
        </button>
      </nav>
    );
}

export default NavbarNew