import React from 'react'
import '../css/Footer.css';
import {Link} from 'react-router-dom'
import FooterLogo from '../assets/images/RIO-lOGO-1.png';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import US from '../assets/images/US.jpg';
import India from '../assets/images/India.jpg';
import { FaQuora } from "react-icons/fa6";

 function LandingFooter() {
  return (
    <div>
        <div className='footer'>
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6">
                <img src={FooterLogo} alt='' className='footer_title'/>
                
            <div className='social_media'>
            
          <Link to='https://www.facebook.com/riobizsols/' target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'#FFFF'}}><FacebookRoundedIcon color='#fff' className='social_icon' sx={{ fontSize: 30 }}/></Link>
          <Link to='https://www.linkedin.com/company/rio-business-solutions-usa/' target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'#FFFF'}}><LinkedInIcon color='#fff' sx={{ fontSize: 30 }}  className='social_icon'/></Link>
            <Link to='https://www.youtube.com/channel/UC37z3CVHMraqRnrGrlucZ0g' target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'#FFFF'}}><YouTubeIcon sx={{ fontSize: 30 }} className='social_icon'/></Link>
            <Link to='https://www.instagram.com/rio_cbe/' target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'#FFFF'}}><InstagramIcon sx={{ fontSize: 30 }} className='social_icon'/></Link>
         </div>
                </div>
                <div className="col-lg-4 col-md-6">
                <h5 className='footer_title1'>Services</h5>
             <ul className='footer_menu'>
               <Link to='#' style={{textDecoration:'none', color:'#BDBBBB'}}><li>Digital Marketing</li></Link>
               <Link to='#' style={{textDecoration:'none', color:'#BDBBBB'}}><li>Web Development</li></Link>
               <Link to='#' style={{textDecoration:'none', color:'#BDBBBB'}}><li>App Development</li></Link>
               <Link to='#' style={{textDecoration:'none', color:'#BDBBBB'}}><li>Staffing</li></Link>
               <Link to='#' style={{textDecoration:'none', color:'#BDBBBB'}}><li>Odoo ERP</li></Link>
               <Link to='#' style={{textDecoration:'none', color:'#BDBBBB'}}><li>IPR Services</li></Link>
             </ul>
                </div>
                <div className="col-lg-4 col-md-6">
                <h5 className='footer_title2'>Let's Connect</h5>
                <ul >
            
                 <li className='footer_menu2'>
                     <WifiCalling3Icon className='footer_icon'/>
                     
        +91 88849 10777
      </li>
                    <li className='footer_menu2'>
                     <span><WifiCalling3Icon className='footer_icon'/>
                     
        +1 209-297-7062
      </span></li>
                        <li className='footer_menu2'>
                         <AttachEmailIcon className='footer_icon'/>
                         info@riobizsols.com</li>

                         </ul>

                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div id='container_oru_loca' className="col-custom col-12 col-md-6">

                </div>
                <div className="col-custom col-12 col-md-6">
                    <h1 className='foot-loc'>Our Locations</h1>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-4 gduj">
                <Link  to='#' style={{textDecoration:'none', color:'#BDBBBB', marginTop:"160px"}}><p>Privacy Policy</p></Link>
                <p className='copyright'>© Copyright 2024 By <b>RIO BizSols PVT LTD</b></p>
                </div>
                
                <div className="col-12 col-md-4">
                <img src={US} alt="US" className='footer_location_img1'/>
                <h5 className='location_title-h'>USA Office</h5>
                  <p className='location_title'>180 Steuart St, #192750 <br/>SanFrancisco, CA 94119, USA</p>
                </div>
                <div className="col-12 col-md-4">
                <img src={India} alt="US" className='footer_location_img2'/>
                <h5 className='location_title1-h'>INDIA Office</h5>
           <p className='location_title1'>No.174/96A, Barathi Colony Main Road,<br/> Peelamedu, Coimbatore – <br/>641004, India</p>
       
            </div>
          </div>
        </div>
      </div>

      <footer className="footer-mob">
      {/* Top section: Services and Let's Connect */}
      <div className="footer-grid-mob">
        <div className="services-mob">
          <h3>Services</h3>
          <ul>
            <li>Digital Marketing</li>
            <li>Web Development</li>
            <li>App Development</li>
            <li>Staffing</li>
            <li>Odoo ERP</li>
            <li>IPR Services</li>
          </ul>
        </div>
        <div className="connect-mob">
          <h3>Let's Connect</h3>
          <ul>
            <li><WifiCalling3Icon className='footer_icon'/> +91 88849 10777</li>
            <li><WifiCalling3Icon className='footer_icon'/> +1 209-297-7062</li>
            <li><AttachEmailIcon className='footer_icon'/> info@riobizsols.com</li>
          </ul>
        </div>
      </div>

      {/* Middle section: Locations */}
      <div className="locations-mob">
        <h3>Our Locations</h3>
        <div className="locations-grid-mob">
          <div className="location-mob">
            <img
              src={US}
              alt="USA Office"
              className="location-img-mob"
            />
            <p>USA Office</p>
            <p>180 Steuart St, #192750 San Francisco, CA 94119, USA</p>
          </div>
          <div className="location-mob">
            <img
              src={India}
              alt="India Office"
              className="location-img-mob"
            />
            <p>INDIA Office</p>
            <p>No.174/96A, Barathi Colony, Main Road, Peelamedu, Coimbatore - 641004, India</p>
          </div>
        </div>
      </div>

      {/* Bottom section: Logo, Icons, and Copyright */}
      <div className="footer-bottom-mob">
        <div className="logo-mob">
          <img src={FooterLogo} alt="RIO Logo" />
        </div>
        <div className="social-icons-mob">
        <Link to='https://www.facebook.com/riobizsols/' target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'#FFFF'}}><FacebookRoundedIcon color='#fff' className='social_icon' sx={{ fontSize: 30 }}/></Link>
          <Link to='https://www.linkedin.com/company/rio-business-solutions-usa/' target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'#FFFF'}}><LinkedInIcon color='#fff' sx={{ fontSize: 30 }}  className='social_icon'/></Link>
            <Link to='https://www.youtube.com/channel/UC37z3CVHMraqRnrGrlucZ0g' target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'#FFFF'}}><YouTubeIcon sx={{ fontSize: 30 }} className='social_icon'/></Link>
            <Link to='https://www.instagram.com/rio_cbe/' target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'#FFFF'}}><InstagramIcon sx={{ fontSize: 30 }} className='social_icon'/></Link>
        </div>
        <p className="copyright-mob">
          © Copyright 2024 RIO BizSols Pvt Ltd
        </p>
      </div>
    </footer>
    </div>
  
  )
}

export default LandingFooter
