import React, { useState } from 'react';
import '../../css/Contact.css';
import axios from 'axios';


const ContactFrom = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    email: '',
    phone: '',
    company: '',
    location: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear the error message for the field being edited
    if (value.trim() !== '') {
      setErrors({ ...errors, [name]: '' });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    // Validation for empty fields
    if (value.trim() === '') {
      setErrors({ ...errors, [name]: 'Please complete this required field' });
    }

    // Additional validation for phone number (digits only)
    if (name === 'phone' && value.trim() !== '' && !/^\d+$/.test(value)) {
      setErrors({ ...errors, phone: 'Please enter a valid phone number (digits only)' });
    }
  };

  const validate = () => {
    let newErrors = {};

    // Required field validation
    if (!formData.firstname.trim()) {
      newErrors.firstname = 'Please complete this required field';
    }
    // if (!formData.lastname.trim()) {
    //   newErrors.lastname = 'Please complete this required field';
    // }
    if (!formData.email.trim()) {
      newErrors.email = 'Please complete this required field';
    }
    if (!formData.company.trim()) {
      newErrors.company = 'Please complete this required field';
    }
    if (!formData.location.trim()) {
      newErrors.location = 'Please complete this required field';
    }
    if (!formData.message.trim()) {
      newErrors.message = 'Please complete this required field';
    }

    // Phone validation (digits only)
    if (!formData.phone.trim()) {
      newErrors.phone = 'Please complete this required field';
    } 
    if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = 'Please enter a valid phone number (digits only)';
    }

    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
    try {
        const response = await axios.post('https://ind.riobizsols.com/send-email', formData);
        if (response.data.success) {
            alert('Email sent successfully!');
        } else {
            alert('Failed to send email.');
        }
    } catch (error) {
        console.error('There was an error sending the email:', error);
        alert('Error sending email!');
    }

    // Clear the form
    setFormData({
      firstname: '',
      email: '',
      phone: '',
      company: '',
      location: '',
      message: ''
    });
  };
};

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validate()) {
  //     const portalId = process.env.REACT_APP_PORTAL_ID;
  //     const formId = process.env.REACT_APP_FORM_ID;
  //     const region = process.env.REACT_APP_REGION;
      
  //     const url = `https://forms.hubspot.com/uploads/form/v2/${portalId}/${formId}`;      
  //     try {
  //       const response = await fetch(url, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //         body: new URLSearchParams({
  //           "firstname": formData.firstname,
  //           "lastname": formData.lastname,
  //           "email": formData.email,
  //           "phone": formData.phone,
  //           "company": formData.company,
  //           "message": formData.message
  //         })
  //       });
      
  //       // Check if the response status is 204 (No Content)
  //       if (response.status === 204) {
  //         console.log('Form submitted successfully with no response body');
  //         alert('Form submitted successfully!');
  //       } else if (!response.ok) {
  //         throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
  //       }

  //       setFormData({
  //         firstname: '',
  //         lastname: '',
  //         email: '',
  //         phone: '',
  //         company: '',
  //         message: ''
  //       });
      
      
  //     } catch (error) {
  //       console.error('Error:', error);
  //       alert('Error submitting form');
  //     }

  //   };
  //      }

  return (
    <div style={{marginTop:-40}}>
      <div className="contact-form_bg">
      {/* <div className="headlines">
          <h2 className='headline-1'>Talk to expert</h2>
          <h2 className='headline-2'>Looking for the right expertise to grow your business? Our experts are here to help take your business to new heights of success.</h2>
        </div> */}
        <div className="main-form">
          <form onSubmit={handleSubmit}>
            <div className={`form-group ${errors.firstname ? 'error' : ''}`}>
              <label htmlFor="name">Enter your name <span>*</span></label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.firstname ? 'error-input' : ''}
              />
              {errors.firstname && <span className="error-message">{errors.firstname}</span>}
            </div>

            {/* <div className={`form-group ${errors.lastname ? 'error' : ''}`}>
              <label htmlFor="name">Enter your lastname <span>*</span></label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.lastname ? 'error-input' : ''}
              />
              {errors.lastname && <span className="error-message">{errors.lastname}</span>}
            </div> */}

            <div className={`form-group ${errors.email ? 'error' : ''}`}>
              <label htmlFor="email">Email <span>*</span></label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.email ? 'error-input' : ''}
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>

            <div className={`form-group ${errors.phone ? 'error' : ''}`}>
              <label htmlFor="phone">Phone number <span>*</span></label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.phone ? 'error-input' : ''}
              />
              {errors.phone && <span className="error-message">{errors.phone}</span>}
            </div>

            <div className={`form-group ${errors.company ? 'error' : ''}`}>
              <label htmlFor="company">Company name <span>*</span></label>
              <input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.company ? 'error-input' : ''}
              />
              {errors.company && <span className="error-message">{errors.company}</span>}
            </div>

           <div className={`form-group ${errors.location ? 'error' : ''}`}>
              <label htmlFor="company">Company location <span>*</span></label>
              <input
                type="text"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.location ? 'error-input' : ''}
              />
              {errors.location && <span className="error-message">{errors.location}</span>}
            </div>

            <div className={`form-group ${errors.message ? 'error' : ''}`}>
              <label htmlFor="message">Message <span>*</span></label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.message ? 'error-input' : ''}
              ></textarea>
              {errors.message && <span className="error-message">{errors.message}</span>}
            </div>

            <button className='s-btn' type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactFrom;
