import React, { useEffect } from "react";

const Analytics = () => {
  useEffect(() => {
    // Load Google Tag Manager
    const gtmScript = document.createElement("script");
    gtmScript.src = "https://www.googletagmanager.com/gtag/js?id=G-VH7FR8Q8HL";
    gtmScript.async = true;
    document.head.appendChild(gtmScript);

    // Initialize GTM and Event Snippet
    const initGTM = document.createElement("script");
    initGTM.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'G-VH7FR8Q8HL');
      gtag('event', 'conversion', {'send_to': 'AW-10840897350/bStaCMCQ4YMYEMbuq7Eo'});
    `;
    document.head.appendChild(initGTM);

    return () => {
      // Cleanup scripts
      document.head.removeChild(gtmScript);
      document.head.removeChild(initGTM);
    };
  }, []);

  return null; // No UI needed
};

export default Analytics;
