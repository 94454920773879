import React, { useState } from "react";
import "../css/NavbarNew.css";
import { HashLink } from 'react-router-hash-link';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-MKJW5WZQ', // Replace with your GTM ID
};
TagManager.initialize(tagManagerArgs);


const LandingHeader = ({openModal}) => {

  const handleButtonClickheader = () => {
    // Push the event to GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'button_click',
      button_name: 'headerbutton',
      button_location: 'Landing Page',
    });

    console.log('GTM event pushed: Staffing Solutions button clicked');
  };

    const logo = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599933/rio_main_website/fjap8mgbuzuvmzixb0qq.png"
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  
    const handleToggleMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    };
  
    const handleMenuClick = () => {
      setIsMobileMenuOpen(false); // Close menu after clicking
      setIsServicesDropdownOpen(false);
    };

    const toggleServicesDropdown = () => {
        setIsServicesDropdownOpen(!isServicesDropdownOpen);
      };

      const scrollWithOffset = (el) => {
        const yOffset = -50; // Adjust this value as needed
        const yPosition = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
      };
     
      const handleButtonClick = () => {
        handleButtonClickheader(); // Call the existing button functionality
        openModal(); // Then open the modal
      }

    return (
      <nav className="navbar" style={{backgroundColor:"#eef3f626"}}>
        <div className="navbar-logo">
            < HashLink  to='#'>
            <img src={logo} alt='logo'/>
            </ HashLink >
        </div>
        <div className={`navbar-links ${isMobileMenuOpen ? "active_1" : ""}`}>
          <ul className="lp_ulist">
            <li onClick={handleMenuClick}>
              < HashLink  to="#">Home</ HashLink >
            </li>
            <li onClick={handleMenuClick}>
              < HashLink  to="/staffing-landing-page#sf_land_ser_container" scroll={scrollWithOffset}>Staffing</ HashLink >
            </li>
            <li onClick={handleMenuClick}
               className="services-menu"
            >
              < HashLink  to="/staffing-landing-page#landing_contact" scroll={scrollWithOffset}>
              Contact us
              </ HashLink >
              
            </li>
            <li onClick={handleMenuClick}>
              < HashLink  to="/staffing-landing-page#faq-container" scroll={scrollWithOffset}>FAQs</ HashLink >
            </li>
          </ul>

          <div onClick={handleMenuClick} className="header_cb">
        < HashLink  to="/staffing-landing-page">
        <button onClick={handleButtonClick} className="get_started_lp">Get Started</button></ HashLink >
        </div>
        </div>

        {/* <div onClick={handleMenuClick} className="header_cb_2">
        < HashLink  to='/staffing-landing-page#landing_contact'>
        <button onClick={handleButtonClickheader} className="get-started">Contact Us</button></ HashLink >
        </div> */}
        <button className="menu-toggle" onClick={handleToggleMenu}>
          ☰
        </button>
      </nav>
    );
}

export default LandingHeader