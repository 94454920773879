import React, { useState, useEffect  } from "react";
import '../../pages/landingpage/staffing_contact.css'
import axios from 'axios';
import { AiFillCaretRight } from "react-icons/ai";
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';

const tagManagerArgs = {
  gtmId: 'GTM-MKJW5WZQ', // Replace with your GTM ID
};
TagManager.initialize(tagManagerArgs);

const StaffContactForm = () => {

  const navigate = useNavigate();

  const [requirements, setRequirements] = useState([]);

  const [formData, setFormData] = useState({
    firstname: "",
    email: "",
    phone: "",
    company: "",
    location: "",
    projectRequirements: "", 
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const fetchProjectRequirements = async () => {
      try {
        const response = await axios.get('https://ind.riobizsols.com/project-requirements');
        setRequirements(response.data); 
      } catch (error) {
        console.error('Error fetching project requirements:', error);
      }
    };

    fetchProjectRequirements();
  }, []); 


  const handleSubmit = async (e) => {
    e.preventDefault();

     // List of blocked public domains
  const blockedDomains = ["gmail.com", "yahoo.com", "zoho.com", "outlook.com", "outlook.com", "hotmail.com", "live.com", "icloud.com", "ymail.com", "protonmail.com", "tutanota.com", "rediffmail.com", "hushmail.com", "mail.com"];
  
  // Extract the domain from the email
  const emailDomain = formData.email.split("@")[1];

  // Check if the domain is in the blocked list
  if (blockedDomains.includes(emailDomain)) {
    alert("Public email domains are not allowed. Please use your official email.");
    return; // Stop further execution
  }

    try {
      const response = await axios.post('https://ind.riobizsols.com/send-email', formData);

      // const response = await axios.post('https://ind.riobizsols.com/send-email', formData);
      if (response.data.success) {
          alert('Email sent successfully!');
      } else {
          alert('Failed to send email.');
      }
  } catch (error) {
      console.error('There was an error sending the email:', error);
      alert('Error sending email!');
  }

  // Clear the form
  setFormData({
    firstname: "",
    email: "",
    phone: '',
    company: "",
    location: "",
    projectRequirements: "", 
    message: "",
  });

  navigate('/our-service/staffing');
    
  };

  const handleButtonClick2 = () => {
    // Push the event to GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'button_click',
      button_name: 'formbutton',
      button_location: 'Landing Page',
    });

    console.log('GTM event pushed: Staffing Solutions button clicked');
  };

  return (
    <form className="contact-form-land" onSubmit={handleSubmit}>
      <div className="form-row1">
        <input
          type="text"
          name="firstname"
          placeholder="Full Name"
          value={formData.firstname}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="phone"
          placeholder="Phone Number (Optional)"
          value={formData.phone}
          onChange={handleChange}
        />
      </div>
      <div className="form-row1">
        <input
          type="email"
          name="email"
          placeholder="Official Mail ID"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="company"
          placeholder="Company Name"
          value={formData.company}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-row1">
        <input
          type="text"
          name="location"
          placeholder="City"
          value={formData.location}
          onChange={handleChange}
          required
        />
         <select
          name="projectRequirements"
          className=""
          value={formData.projectRequirements}
          onChange={handleChange}
          required
        >
          <option value="" disabled> Project Requirements</option>
          {requirements.map((requirement, index) => (
            <option key={index} value={requirement}>{requirement}</option>
          ))}
        </select>

      </div>
      <div className="form-row1">
        <textarea
          name="message"
          placeholder="Share Your Requirements (Optional)"
          value={formData.message}
          onChange={handleChange}
        />
      </div>
      <button type="submit" className="submit-button"  onClick={handleButtonClick2}>
        Submit <AiFillCaretRight className='sf_land_icon_cont'/>
      </button>
    </form>
  );
};

export default StaffContactForm;
