import React , {useState} from 'react'
import landing from '../../../assets/images/staffing_land.png'
import  '../../pages/landingpage/staffinglanding.css'
import { HashLink } from 'react-router-hash-link';
import { AiFillCaretRight } from "react-icons/ai";
import right from '../../../assets/images/sf_right.png'
import arrowright from '../../../assets/images/arrowright.png'
import arrowleft from '../../../assets/images/arrowleft.png'
import sericon1 from '../../../assets/images/sericon1.png'
import sericon2 from '../../../assets/images/sericon2.png'
import sericon3 from '../../../assets/images/sericon3.png'
import sericon4 from '../../../assets/images/sericon4.png'
import sericon5 from '../../../assets/images/sericon5.png'
import sericon6 from '../../../assets/images/sericon6.png'
import sericon7 from '../../../assets/images/sericon7.png'
import sericon8 from '../../../assets/images/sericon8.png'
import icon1 from '../../../assets/images/1509639 1.png'
import icon2 from '../../../assets/images/4944230 1.png'
import icon3 from '../../../assets/images/6091840 1.png'
import icon4 from '../../../assets/images/6487663 1.png'
import icon5 from '../../../assets/images/7556901 1.png'
import icon6 from '../../../assets/images/9782496 1.png'
import aspnet from "../../../assets/images/Group 179.png";
import php from "../../../assets/images/Group 180.png";
import python from "../../../assets/images/Group 181.png";
import spring from "../../../assets/images/Group 182.png";
import nodejs from "../../../assets/images/Group 183.png";
import kafka from "../../../assets/images/Group 184.png";
import sap from "../../../assets/images/Group 185.png";
import vuejs from "../../../assets/images/Group 186.png";
import linux from "../../../assets/images/Group 187.png";
import javascript from "../../../assets/images/Group 188.png";
import aws from "../../../assets/images/Group 189.png";
import react from "../../../assets/images/Group 190.png";
import api from "../../../assets/images/Group 191.png";
import TagManager from 'react-gtm-module';
import StaffContactForm from './staffing_contact';
import ClientLogoLanding from './ClientLogoLanding';
import Analytics from './Analytics';

// const tagManagerArgs = {
//   gtmId: 'GTM-MKJW5WZQ', 
// };
// TagManager.initialize(tagManagerArgs);



const StaffingLandingPage = ({isModalOpen, closeModal, openModal}) => {

  const [openIndexes, setOpenIndexes] = useState([]); // Array to track open questions

  


    // const handleButtonClickStaffing = () => {
        
    //     window.dataLayer = window.dataLayer || [];
    //     window.dataLayer.push({
    //       event: 'button_click',
    //       button_name: 'Staffing Solutions',
    //       button_location: 'Landing Page',
    //     });
    
    //     console.log('GTM event pushed: Staffing Solutions button clicked');
    //   };

    //   const handleButtonClickstarted = () => {
        
    //     window.dataLayer = window.dataLayer || [];
    //     window.dataLayer.push({
    //       event: 'button_click',
    //       button_name: 'button',
    //       button_location: 'Landing Page',
    //     });
    
    //     console.log('GTM event pushed: Staffing Solutions button clicked');
    //   };
    //   const handleButtonClickstarted1 = () => {
        
    //     window.dataLayer = window.dataLayer || [];
    //     window.dataLayer.push({
    //       event: 'button_click',
    //       button_name: 'button1',
    //       button_location: 'Landing Page',
    //     });
    
    //     console.log('GTM event pushed: Staffing Solutions button clicked');
    //   };

    //   const handleButtonClickstarted2 = () => {
       
    //     window.dataLayer = window.dataLayer || [];
    //     window.dataLayer.push({
    //       event: 'button_click',
    //       button_name: 'button2',
    //       button_location: 'Landing Page',
    //     });
    
    //     console.log('GTM event pushed: Staffing Solutions button clicked');
    //   };
    //   const handleButtonClickstarted3 = () => {
      
    //     window.dataLayer = window.dataLayer || [];
    //     window.dataLayer.push({
    //       event: 'button_click',
    //       button_name: 'button3',
    //       button_location: 'Landing Page',
    //     });
    
    //     console.log('GTM event pushed: Staffing Solutions button clicked');
    //   };
    //   const handleButtonClickstarted4 = () => {
        
    //     window.dataLayer = window.dataLayer || [];
    //     window.dataLayer.push({
    //       event: 'button_click',
    //       button_name: 'button4',
    //       button_location: 'Landing Page',
    //     });
    
    //     console.log('GTM event pushed: Staffing Solutions button clicked');
    //   };
    //   const handleButtonClickstarted5 = () => {
      
    //     window.dataLayer = window.dataLayer || [];
    //     window.dataLayer.push({
    //       event: 'button_click',
    //       button_name: 'button5',
    //       button_location: 'Landing Page',
    //     });
    
    //     console.log('GTM event pushed: Staffing Solutions button clicked');
    //   };

      const items = [
        {
          icon:`${icon1}`, // Replace with the icon you want
          title: "Expertise Across Industries",
          description:
            "With years of experience across various sectors, we bring a deep understanding of your business challenges, ensuring relevant and effective solutions.",
        },
        {
          icon:`${icon6}`, // Replace with the icon you want
          title: "Tailored Solutions",
          description:
            "We don’t believe in one-size-fits-all. Our approach is customized to match your specific goals, ensuring optimal results every time.",
        },
        {
          icon:`${icon5}`, // Replace with the icon you want
          title: "Faster Hiring, Guaranteed",
          description:
            "We understand that time is of the essence. Our streamlined hiring process ensures you get the right candidates quickly, without compromising quality.",
        },
      ];
      const items1 = [
        {
          icon1: `${icon4}`, // Replace with the icon you want
          title1: "Replacement Guarantee",
          description1:
            "We stand behind our placements. If a candidate doesn’t meet your expectations, we offer a hassle-free replacement to ensure you get the right candidates quickly, without compromising quality.",
        },
        {
          icon1:`${icon2}`, // Replace with the icon you want
          title1: "24/7 Support",
          description1:
            "Whether you need assistance with a placement or have questions about the process, our team is here for you at any time.",
        },
        {
          icon1:`${icon3}`, // Replace with the icon you want
          title1: "Cost-Effective",
          description1:
            "We offer competitive pricing without compromising the quality of our service. Get top-tier talent at the right cost.",
        },
      ];

      const technologies = [
        {  icon: aspnet },
        {  icon: php },
        { icon: python },
        {  icon: spring },
        { icon: nodejs },
        {  icon: kafka },
        {  icon: sap },
      ];
      const technologies1 = [
        { icon: vuejs },
        {  icon: linux },
        {  icon: javascript },
        {  icon: aws },
        { icon: react },
        { icon: api },
      ];


      const faqData = [
        {
          question: "How do I get started?",
          answer:
            "Getting started is easy! Simply reach out to us through the contact form or give us a call, and we’ll guide you through the process.",
        },
        {
          question: "How quickly can you help me hire candidates?",
          answer: "We specialize in fast and efficient hiring. Depending on your requirements, we can provide qualified candidates within days, ensuring minimal downtime for your business.",
        },
        {
          question: "Do you offer a replacement guarantee?",
          answer:
            "Yes! If a placed candidate backs out within the first month of employment, we provide a hassle-free replacement at no additional cost. We’re committed to ensuring your satisfaction and we will work quickly to find the right fit for your team.",
        },
        {
          question: "What industries do you specialize in?",
          answer: "We have experience working across multiple industries, including IT non IT, finance, healthcare, manufacturing, hotel industry and more. Our recruitment strategies are adaptable to your specific business needs.",
        },
        {
          question: "How do you ensure the quality of candidates?",
          answer:
            "We have a thorough vetting process, which includes interviews, skill assessments, and reference checks to ensure we provide only the most qualified candidates.",
        },
        {
          question: "Can you assist with both temporary and permanent staffing?",
          answer: "Yes! Whether you need short-term, long term, or project-based staffing, we offer flexible solutions to meet your exact requirements.",
        },
      ];
    
      const toggleAccordion = (index) => {
        if (openIndexes.includes(index)) {
          // If the question is already open, close it
          setOpenIndexes(openIndexes.filter((i) => i !== index));
        } else {
          // Otherwise, open it
          setOpenIndexes([...openIndexes, index]);
        }
      };

      const handleButtonClick = () => {
        // handleButtonClickstarted(); // Call the existing button functionality
        openModal(); // Then open the modal
      };
      const handleButtonClick1 = () => {
        // handleButtonClickstarted1(); // Call the existing button functionality
        openModal(); // Then open the modal
      };
      const handleButtonClick2 = () => {
        // handleButtonClickstarted2(); // Call the existing button functionality
        openModal(); // Then open the modal
      };
      const handleButtonClick3 = () => {
        // handleButtonClickstarted3(); // Call the existing button functionality
        openModal(); // Then open the modal
      };
      const handleButtonClick4 = () => {
        // handleButtonClickstarted4(); // Call the existing button functionality
        openModal(); // Then open the modal
      };
      const handleButtonClick5 = () => {
        // handleButtonClickstarted5(); // Call the existing button functionality
        openModal(); // Then open the modal
      };

      const scrollWithOffset = (el) => {
        const yOffset = -50; // Adjust this value as needed
        const yPosition = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
      };
    
     
    
     
  return (
    <div>
      <Analytics />
        <div className='sf_land_bg'>
            <div className='sf_land_container'>
             <img src={landing} alt="landing" className='sf_land_image' />
             <div className="sf_land_text_column">
             <h1 className='sf_land_title'>
             Complete <span className='span1'>Staffing Solutions,</span> <br /> All in one place </h1>
             <p className='sf_land_para'>Looking for the perfect hire? We’ve got you covered. From sourcing top talent to seamless onboarding, Our end-to-end recruitment services are designed to meet your business needs effortlessly .</p>
             {/* <HashLink to="#sf_land_ser_container" scroll={scrollWithOffset}><button className="sf_land_button" onClick={handleButtonClickStaffing}>Staffing Solutions <AiFillCaretRight className='sf_land_icon'/></button></HashLink> */}
             <HashLink to="#sf_land_ser_container" scroll={scrollWithOffset}><button className="sf_land_button" >Staffing Solutions <AiFillCaretRight className='sf_land_icon'/></button></HashLink>

                </div>

            </div>
        </div>
        <div className='sf_bg'>
        <div className='sf_land_container'>
            <img src={right} alt="" className='sf_right_image'/>
            <div className="sf_land_text_column">
             <h1 className='sf_right_title'>Find the right fit, Every time</h1>
             <p className='sf_right_para'>Skip the hassle and get access to top talent tailored to your needs. With just a few clicks, we’ll help you build the perfect team.</p>
            
             <HashLink to=""><button className="sf_right_button" onClick={handleButtonClick}>Get Started <AiFillCaretRight className='sf_right_icon'/></button></HashLink>
             {/* <HashLink to=""><button className="sf_right_button" >Get Started <AiFillCaretRight className='sf_right_icon'/></button></HashLink> */}

                </div>
        </div>
        </div>
        <div>
            <h2 className='sf_growth'>How does Comprehensive Staffing Solutions contribute to the <span className='span1'>growth of your business?</span></h2>

        </div>
        <div className='sf_growth_clo'>
           
        <div className='sf_growth_bg'>
                <h4>Access to Top Talent
                </h4>
                <p>Gain access to our extensive network of skilled professionals across various industries  and disciplines.</p>
            </div>
           <div className='sf_growth_bg'>
            <h4>Expert Matching</h4>
            <p>Our rigorous screening and vetting process ensures that we match you with candidates who not only have the right skills but also fit seamlessly into your company culture.</p>
           </div>
           
            <div className='sf_growth_bg'>
            <h4>Streamlined Hiring <br/>Processes</h4>
            <p>Save time and resources with our efficient and hassle-free hiring processes, allowing you to focus on your core business priorities.</p>
           </div>
        </div>
        <hr className='divider-sf' />
        
        <div id="sf_land_ser_container">
        <div className='sf_land_service'>
            <h2 className='sf_growth'>Here’s how we help you<br />recruit the <span className='span1'>best talent!</span></h2>
            <div className="sf_land_ser_col">
                <div className='sf_land_ser_col_bg'>
                    <div className='sf_land_ser_title'>
                    <h4>Contingent Staffing</h4>
                    <img src={sericon1} alt="sericon1" />
                    
                    </div>
                    <div className="sf_land_ser_para">
                   <p>Our contingent staffing solutions provide businesses with the flexibility to scale their workforce quickly in response to project demands. Whether for short-term projects or seasonal surges, we connect you with top-tier talent to meet your specific needs, all while offering cost-effective, flexible staffing options.</p> 
                    
                   <div className="sf_land_link">
                    <HashLink to=""><button className='sf_land_ser_button' onClick={handleButtonClick1}> Get Started  <AiFillCaretRight className='sf_right_icon1'/></button></HashLink>
                    {/* <HashLink to=""><button className='sf_land_ser_button'> Get Started  <AiFillCaretRight className='sf_right_icon1'/></button></HashLink> */}

                    </div>                    
                    </div>
                    
                </div>
                
                <div className='sf_land_ser_col_bg'>
                    <div className='sf_land_ser_title'>
                    <h4>Remote Staffing</h4>
                    <img src={sericon2} alt="" />
                    
                    </div>
                    <div className="sf_land_ser_para">
                    <p>Transform the way your business operates by accessing a global pool of talent through our remote staffing services. Reduce overhead costs, enhance productivity, and ensure continuous operations, regardless of location. Our remote professionals are experts in virtual collaboration, seamlessly integrating with your team.</p> 
                    
                    <div className="sf_land_link">
                    <HashLink to=""><button className='sf_land_ser_button' onClick={handleButtonClick2}> Get Started  <AiFillCaretRight className='sf_right_icon1'/></button></HashLink>
                    {/* <HashLink to=""><button className='sf_land_ser_button'> Get Started  <AiFillCaretRight className='sf_right_icon1'/></button></HashLink> */}

                    </div>
                    </div>
                </div>
            </div>

            <div className="sf_land_ser_col_next">
                <div className='sf_land_ser_col_bg'>
                    <div className='sf_land_ser_title'>
                    <h4>Contract Staffing</h4>
                    <img src={sericon3} alt="" />
                    
                    </div>
                    <div className="sf_land_ser_para">
                   <p>For specialized expertise or projects with defined timelines, our contract staffing services offer skilled professionals who bring immediate value to your team. Enjoy faster onboarding, reduced hiring risks, and the flexibility to adapt to evolving project scopes with our contract staffing solutions.</p> 
                    
                   <div className="sf_land_link_3">
                    <HashLink to=""><button className='sf_land_ser_button' onClick={handleButtonClick3}> Get Started  <AiFillCaretRight className='sf_right_icon1'/></button></HashLink>
                    {/* <HashLink to=""><button className='sf_land_ser_button' > Get Started  <AiFillCaretRight className='sf_right_icon1'/></button></HashLink> */}

                    </div>                    </div>
                    
                </div>
                
                <div className='sf_land_ser_col_bg'>
                    <div className='sf_land_ser_title'>
                    <h4>IT Recruitment</h4>
                    <img src={sericon4} alt="" />
                    
                    </div>
                    <div className="sf_land_ser_para">
                    <p>Navigating the evolving IT landscape requires access to top talent. Our IT recruitment services help businesses connect with skilled professionals across various IT roles. With our extensive network and expertise in the recruitment process, we support organizations in building strong, innovative teams to drive success in an increasingly technology-driven world.</p> 
                    <div className="sf_land_link">
                    <HashLink to=""><button className='sf_land_ser_button' onClick={handleButtonClick4}> Get Started  <AiFillCaretRight className='sf_right_icon1'/></button></HashLink>
                    {/* <HashLink to=""><button className='sf_land_ser_button' > Get Started  <AiFillCaretRight className='sf_right_icon1'/></button></HashLink> */}

                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div className='sf_bg'>
        <h2 className='sf_what'>What makes us stand apart ?</h2>
        <div className="sf_land_ser_col1">
                <div className='what_box'>
                    <div className='sf_land_ser_title1'>                   
                    <img src={sericon5} alt="" />
                    <h4>Industry Expertise</h4>                  
                    </div>
                    <div className="sf_land_ser_para1">
                   <p> With years of experience in the staffing industry, RIO understands the nuances of different sectors and can provide you with tailored staffing solutions to meet your organization's needs.</p> 
                    </div>
                </div>                
                <div className='what_box'>
                    <div className='sf_land_ser_title1'>                    
                    <img src={sericon6} alt="" />
                    <h4>Personalized Approach</h4>                   
                    </div>
                    <div className="sf_land_ser_para1">
                   <p> We believe in building long-term partnerships with our clients and take the time to understand their unique requirements to deliver personalized staffing solutions that align with their goals.</p>                    
                    </div>                 
                </div>
            </div>
            <div className="sf_land_ser_col2">
                <div className='what_box'>
                    <div className='sf_land_ser_title1'>
                    
                    <img src={sericon7} alt="" />
                    <h4>Quality Assurance</h4>
                    
                    </div>
                    <div className="sf_land_ser_para1">
                   <p>Our commitment to quality is unwavering. RIO’s talent acquisition team adheres to the highest standards of professionalism and integrity in everything we do, ensuring that you receive the best possible service. </p>                                       
                    </div>                   
                </div>              
                <div className='what_box'>
                    <div className='sf_land_ser_title1'>                  
                    <img src={sericon8} alt="" />
                    <h4>Customer Satisfaction</h4>                   
                    </div>
                    <div className="sf_land_ser_para1">
                   <p>Our commitment to client satisfaction is reflected in our strong track record of success. As a trusted leader in IT and Non IT recruitment, RIO has helped countless businesses thrive with tailored solutions that consistently exceed expectations.</p> 
                    </div> 
                </div>
            </div>
            <div className='sf_what1'>
            <HashLink to=""><button className='sf_what_button1' onClick={handleButtonClick5}>Get Started <AiFillCaretRight className='sf_right_icon'/> </button></HashLink>
            {/* <HashLink to=""><button className='sf_what_button1'>Get Started <AiFillCaretRight className='sf_right_icon'/> </button></HashLink> */}

          </div>
        </div>

           <div className="why-choose-us">
               <h2>
                 Why <span>Choose Us?</span>
               </h2>
               <div className="grid-container">
                 {items.map((item, index) => (
                   <div key={index} >

                     <div className="icon">
                      <img src={item.icon} alt="" />
                     </div>
                    <div className='grid-item-lp'>
                     <h3>{item.title}</h3>
                     <p>{item.description}</p>
                   </div>
                   </div>
                 ))}
               </div>

               <div className="grid-container">
                 {items1.map((item, index) => (
                   <div key={index} >

                     <div className="icon">
                      <img src={item.icon1} alt="" />
                     </div>
                    <div className='grid-item-lp'>
                     <h3>{item.title1}</h3>
                     <p>{item.description1}</p>
                   </div>
                   </div>
                 ))}
               </div>
               <hr className='divider-sf' />
             </div>

             <div className="tech-innovations">
                <h2>
                 Empowering businesses with <br /> <span>100+ tech innovations</span>
                </h2>
              <div className="icons-grid">
                {technologies.map((tech, index) => (
                  <div key={index} className="icon-item">
                    <img src={tech.icon} alt='' />
                  </div>
                ))}
              </div>

              <div className="icons-grid">
                {technologies1.map((tech, index) => (
                  <div key={index} className="icon-item">
                    <img src={tech.icon} alt='' />
                  </div>
                ))}
              </div>
              </div>

              <div className="client_logo_lp">
                <h2>Few of Our <span>Clients</span></h2>
                <ClientLogoLanding/>
              </div>

              <div id="faq-container">
                <h1 className="faq-title">FAQs</h1>
                <div className="faq-list">
                  {faqData.map((item, index) => (
                    <div key={index} className="faq-item">
                      <div
                        className="faq-question"
                        onClick={() => toggleAccordion(index)}
                      >
                        <span>{item.question}</span>
                        <span className="faq-arrow">{openIndexes.includes(index) ? "▲" : "▼"}</span>
                      </div>
                      {openIndexes.includes(index) && (
                       <div className="faq-answer">{item.answer}</div>
                     )}
                     <hr className='faq-line' />
                   </div>
                 ))}
               </div>
             </div>

              <div  id='landing_contact'>
              <h2 className='sf_growth'>Talk to expert</h2>
              <p className='sf_growth_para'>Looking for the right expertise to grow your business?<br/>
             Our experts are here to help take your business to new heights of success.</p>
              </div>
              <StaffContactForm/>       

    {isModalOpen && (
        <div className='popup_contact_container'>
          <div className='popup_contact'>
            <button className='popup_close' onClick={closeModal}>
              &times;
            </button>
            <h2>Contact Us</h2>
            <StaffContactForm /> {/* Render your existing form here */}
          </div>
        </div>
      )}      
    </div>
    
  )
}

export default StaffingLandingPage