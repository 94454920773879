import React from 'react';
import '../pages/blog.css'
import { Link } from 'react-router-dom';

const blogPosts = [
  
  { id: 1, 
    title: 'Why Choose RIOBIZSOLS Among Recruitment Companies in the USA', 
    content: "In todays fiercely competitive job market, finding the right talent isnt just a priority - its make or break decision. Whether you're a start-up looking to scale or an established company aiming to fill key positions, recruitment can be a daunting task. So, when it comes to choosing the right recruitment partner, why settle for the ordinary when you can choose extraordinary? That’s where RioBizSols comes in. With its comprehensive recruitment services, RioBizSols stands out among recruitment agencies in the USA", 
    date: '2024-10-05', 
    author: 'Admin' },
  { id: 2, 
    
    title: 'Website Development in India: How RIOBIZSOLS PVT LTD Transforms Ideas into Reality!',
    content: 'In today’s digital era, a powerful online presence is essential for every business of all sizes, and that starts with a great website. RIOBizSols, a leading business solutions provider in India, is at the forefront for transforming the web development game by bringing innovative ideas to life, with a commitment to quality, innovation, and customer satisfaction. RIOBizSols stands out by offering customized solutions that not only meet but exceed client expectation, combining creativity and technology to deliver world class websites.', 
    date: '2024-12-10', 
    author: 'Admin' },
  
    { id: 3, 
      title: 'Web Designing Companies in India: Riobizsols Pvt Ltd’s Unique Approach to Creative Excellence!',
      content: 'In the competitive world of web design, standing out takes more than just pretty visuals- it requires a blend of creativity, technology, and strategy. RioBizSols is one of the few web designing companies in India that has mastered this blend, offering tailored web experiences that are both visually stunning and highly functional.They deliver websites that dont just look great–they perform, engage, and convert.', 
      date: '2024-12-10', 
      author: 'Admin' },

];

const truncateContent = (content, limit) => {
  const words = content.split(' ');
  return words.length > limit ? words.slice(0, limit).join(' ') + '...' : content;
};

const BlogList = () => (
  <div>
    <h1 className='blog_head'>Our Blogs</h1>
    <div className='blog_container'>
    
    {blogPosts.map((post) => (
      <div className='blog_col'>
      <div className='blog_content' key={post.id}>
        <h2 className='blog_title'>{post.title}</h2>
        <p>{truncateContent(post.content, 30)}</p>
        <p>By {post.author} on {post.date}</p>
        <button className='blog_button'><Link to={`/blog/${post.id}` }  style={{ textDecoration: 'none', color: '#fff' }}>Read More</Link></button>
      </div>
      </div>
    ))}
    
    
    </div>
    
  </div>
);

export default BlogList;
