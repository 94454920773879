import React from 'react'
import '../../../css/Clientlogo.css'
import clientlogo1 from '../../../assets/images/amazon-512 1.png';
import clientlogo2 from '../../../assets/images/image 114.png';
import clientlogo3 from '../../../assets/images/image 115.png';
import clientlogo4 from '../../../assets/images/image 116.png';
import clientlogo5 from '../../../assets/images/image 118.png';
import clientlogo6 from '../../../assets/images/image 119.png';
import clientlogo7 from '../../../assets/images/image 120.png';
import clientlogo8 from '../../../assets/images/South 1.png';
import clientlogo9 from '../../../assets/images/Sysmex_company_logo.svg 1.png';
import clientlogo10 from '../../../assets/images/vertex-logo_2 1.png';
import clientlogo11 from '../../../assets/images/Group 193.png';




function ClientLogoLanding() {
   return (
    <div className='client-logo-lp'>
        {/* <h1 className='client'>Our Clients</h1> */}
        <div className='logo'>
        <div className='logo-slid-lp'>
            <img src={clientlogo1} alt="" />
            <img src={clientlogo2} alt=""/>
            <img src={clientlogo3} alt=""/>
            <img src={clientlogo4} alt="" />
            <img src={clientlogo5} alt="" />
            <img src={clientlogo6} alt="" />
            <img src={clientlogo7} alt="" />
            <img src={clientlogo8} alt="" />
            <img src={clientlogo9} alt="" />
            <img src={clientlogo10} alt="" />
            <img src={clientlogo11} alt="" />
        </div>
        </div>
       
        
        </div>
  )
}

export default ClientLogoLanding