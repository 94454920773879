import React from 'react';
import { useParams } from 'react-router-dom';
import '../pages/blog.css'

const blogPosts = [
  { id: 1, 
    title: 'WHY CHOOSE RIOBIZSOLS AMONG RECRUITMENT COMPANIES IN THE USA', 
    // content: "In todays fiercely competitive job market, finding the right talent isnt just a priority - its make or break decision. Whether you're a start-up looking to scale or an established company aiming to fill key positions, recruitment can be a daunting task. So, when it comes to choosing the right recruitment partner, why settle for the ordinary when you can choose extraordinary? That’s where RioBizSols comes in. With its comprehensive recruitment services, RioBizSols stands out among recruitment agencies in the USA", 
    
content: [
  {
    subheading: "",
    description: "In today’s fiercely competitive job market, finding the right talent isn’t just a priority - it’s a make or break decision. Whether you're a start-up looking to scale or an established company aiming to fill key positions, recruitment can be a daunting task. So, when it comes to choosing the right recruitment partner, why settle for the ordinary when you can choose extraordinary? That’s where RioBizSols comes in. With its comprehensive recruitment services, RioBizSols stands out among recruitment agencies in the USA. Here’s why RioBizSols should be your go-to recruitment partner:"
  
  },
  {
    subheading: "All-in-One Staffing Solutions",
    description: "With RioBizSols, you get end-to-end staffing solutions that cover every aspect of hiring. Whether you’re looking to fill temporary, permanent, or contractual positions or need to ramp up your workforce through staff augmentation, we’ve got you covered from the first interview to the final hire. Plus, our post-hire support ensures that you don’t just find great talent—you keep it."
  
  },
  {
    subheading: "Deep Understanding of Diverse Industries",
    description: "RioBizSols has diverse industry experience, making it easy for us to understand specific hiring requirements for different sectors. Our team boasts expertise across a spectrum of industries, from tech and digital marketing to specialised staffing for finance, HR, and more. We’ve been matching top talent with industry-specific roles for years, bringing in candidates that don't just fill a gap—they elevate your business. Think of RioBizSols as the master key that unlocks doors to talent that’s otherwise hard to find."
  
  },
  {
    subheading: "Global Reach with Local Touch",
    description: "What sets RioBizSols apart from others is its ability to recruit locally and internationally. With a presence in both the USA and India, we leverage a global talent pool. This approach allows us to source the best candidates from diverse backgrounds across the globe, ensuring you have access to talent that can add value, no matter the location. We,ve got the connections and reach to make it happen."
  
  },
  {
    subheading: "24/7 Assistance and Quick Turnaround Time",
    description: "In the fast-paced corporate world, timing is everything. Time zones? No problem. Recruitment that fits into your 9-to-5? We think bigger than that. Rio Business Solutions operates 24/7, making us responsive and adaptable to your recruitment needs. Our quick turnaround time ensures you won’t be left waiting to fill crucial positions. We streamline the recruitment process so you can focus on growing your business while we deliver the right candidates."
  
  },

  {
    subheading: "Risk-Free, Worry-Free Hiring & Quality Assurance",
    description: "Hiring can be risky business, but not with us. At RioBizSols, we believe in providing risk-free trials, giving you peace of mind. We understand the importance of hiring the right person, which is why we go beyond to offer quality assurance—so you don’t just hire anyone, you hire the best who’s perfectly aligned with your company culture, goals, and vision."
  
  },
  {
    subheading: "Tailor-Made Recruitment Packages",
    description: "We understand that no two companies are alike, and neither are their hiring needs. That’s why we offer customised recruitment packages designed around your business, whether you need one hire or a full department overhaul. With Rio Business Solutions, you’ll never pay for what you don’t need, and we guarantee a solution that fits your specific goals and budget."
  },
  
  {
    subheading: "Building Long-Term Relationships",
    description: "For us, recruitment is not just about filling roles. It's about building long-term relationships with our clients and candidates. Our aim is to understand your company from the inside out. We immerse ourselves in your company culture, values, and goals to ensure every hire fits seamlessly, setting you up for sustained success."
  },
  
  {
    subheading: "Innovative Hiring Techniques",
    description: "At RioBizSols, we don’t rely on traditional recruitment methods alone. We stay ahead by using cutting-edge technology and innovative hiring techniques like AI-driven candidate sourcing and social media recruitment to tap into hidden talent pools. Our process ensures that we find the best talent efficiently and effectively, while staying aligned with the latest trends in recruitment."
  },
  
  {
    subheading: "Expertise in Freshers and Lateral Hiring",
    description: "Whether you’re after the fresh energy of new graduates or the seasoned expertise of lateral hires, Rio Business Solutions specialises in both. We balance the excitement of fresh talent with the reliability of experienced professionals, helping you find the right mix for your team and business growth."
  },
  
  {
    subheading: "",
    description: "At the end of the day, choosing the right recruitment partner is about more than just filling positions. So, why settle for ordinary when you can have extraordinary? Let RioBizSols make your hiring process smooth, stress-free, and full of top-tier talent"
  },

],
    date: '2024-10-05', 
    author: 'Admin' },
  { id: 2, 
    title: 'WEBSITE DEVELOPMENT IN INDIA : HOW RIOBIZSOLS PVT LTD TRANSFORMS IDEAS INTO REALITY!',
    // content: 'In today’s digital era, a powerful online presence is essential for every business of all sizes, and that starts with a great website. RIOBizSols, a leading business solutions provider in India, is at the forefront for transforming the web development game by bringing innovative ideas to life, with a commitment to quality, innovation, and customer satisfaction. RIOBizSols stands out by offering customized solutions that not only meet but exceed client expectation, combining creativity and technology to deliver world class websites.', 
    
content: [
  {
    subheading: "",
    description: "In today’s digital era, a powerful online presence is essential for every business of all sizes, and that starts with a great website. RIOBizSols, a leading business solutions provider in India, is at the forefront for transforming the web development game by bringing innovative ideas to life, with a commitment to quality, innovation, and customer satisfaction."
  
  },

  {
    subheading: "",
    description: "RIOBizSols stands out by offering customized solutions that not only meet but exceed client expectation, combining creativity and technology to deliver world class websites."
  },

  {
    subheading: "CLIENT CENTRIC APPROACH",
    description: "At RioBizSols, the process starts with a deep dive into understanding the unique needs and goals of each client. Their approach ensures that clients are involved at every stage, from concept to launch. Whether it’s an e-commerce platform or a corporate website, their team ensures every design reflects the brand’s identity. The result? A website that’s functional, sleek, and perfectly aligned with the client’s business goals."
  
  },
  {
    subheading: "INNOVATION & CUSTOMIZATION",
    description: "One of the key differentiators of RioBizSols is its ability to offer highly customized solutions. RioBizSols excels at building future-proof websites using the latest technologies. Whether it’s incorporating cutting-edge UI/UX design or responsive layouts, RioBizSols ensures that every website is a perfect blend of form and function."
  
  },
  {
    subheading: "E-COMMERCE AND BEYOND",
    description: "E-commerce is booming in India, and RioBizSols has extensive experience in building secure, user-friendly e-commerce platforms that drive sales. From product catalogues to payment gateways, they provide end to end e-commerce solutions. Beyond e-commerce, RioBizSols caters to businesses across various industries by building websites that enhance brand value and drive customer engagement"
  },
  {
    subheading: "SEAMLESS EXECUTION AND 24/7 SUPPORT",
    description: "The team at RioBizSols is committed to delivering flawless websites. They rigorously test for performance, compatibility, and security while offering 24/7 support, ensuring that their clients always stay ahead of the competition. Clients also benefit from RioBizSols comprehensive quality assurance process, which includes performance optimization, SEO, and security features."
  
  },

  {
    subheading: "TRANSFORMING VISION INTO REALITY",
    description: "With RioBizSols, websites are more than just digital spaces - they’re powerful tools for business growth. By focusing on client needs, cutting-edge technology, and design innovation, RioBizSols continues to turn ideas into reality, helping businesses thrive online"
  
  },
  {
    subheading: "",
    description: "For businesses looking to take their business online or enhance their digital footprint and achieve long term success, RioBizSols offers the perfect blend of creativity and client centric solutions. Through their expertise, they continue to empower businesses across India and beyond to turn their ideas into reality"
  },
  


],
    date: '2024-12-10', 
    author: 'Admin' },
    { id: 3, 
      title: 'Web Designing Companies in India: Riobizsols Pvt Ltd’s Unique Approach to Creative Excellence!',
      // content: 'In the competitive world of web design, standing out takes more than just pretty visuals- it requires a blend of creativity, technology, and strategy. RioBizSols is one of the few web designing companies in India that has mastered this blend, offering tailored web experiences that are both visually stunning and highly functional.They deliver websites that dont just look great–they perform, engage, and convert.', 
      
content: [
  {
    subheading: "",
    description: "In the competitive world of web design, standing out takes more than just pretty visuals- it requires a blend of creativity, technology, and strategy. RioBizSols is one of the few web designing companies in India that has mastered this blend, offering tailored web experiences that are both visually stunning and highly functional.They deliver websites that don't just look great–they perform, engage, and convert."
  
  },
  {
    subheading: "WHY RIOBIZSOLS STANDS OUT",
    description: " What sets RioBizSols apart? It’s not just about pretty designs–it’s about creating user-centric experiences that not only look great but offer seamless navigation and performance across all devices. They start by diving deep into understanding each client’s business, brand identity, and audience, ensuring every design is customized to fit the client’s unique goals and reflect the brand’s personality."
  
  },
  {
    subheading: "CREATIVITY POWERED BY RIOBIZSOLS",
    description: "RioBizSols doesn’t just follow trends–they set them, using the latest technologies to create modern, interactive websites.Their team brings cutting-edge designs to life using the latest tools, whether it’s building sleek e-commerce platforms or dynamic corporate sites, their designs offer the perfect balance of form and function. Each website is built for performance, with integrated SEO and fast loading speeds to keep visitors engaged."
  
  },
  {
    subheading: "A COLLABORATIVE APPROACH",
    description: "One of RioBizSols strongest assets is their collaborative process. They believe the best designs come from working closely with clients, involving them at every step to ensure the final product aligns with their vision and business goals. This open communication results in websites that are not only aesthetically appealing but also strategically crafted to meet the client’s needs."
  
  },
  {
    subheading: "",
    description: " If you're looking for a web design partner that combines creativity and cutting-edge tech, RioBizSols is where your search ends.With a bold approach that prioritizes engagement, performance, and collaboration, they’re changing the game for businesses looking to stand out in the digital world"
  
  },

],
      date: '2024-12-10', 
      author: 'Admin' },

];
const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogPosts.find((post) => post.id === parseInt(id, 10));

  if (!blog) {
    return <p>Blog not found.</p>;
  } 
  

  return (
    <div className='blog_detail'>
      <h1>{blog.title}</h1>
      <p>By {blog.author} on {blog.date}</p>
      {/* <p>{blog.content}</p> */}
      <div className='blog_section'>
      {blog.content.map((section, index) => (
        <div key={index} className='blog_section_col1'>
        
         {section.subheading && <h2 className='blog_subheading'>{section.subheading}</h2>}
         <p className='blog_description'>{section.description}</p>
         </div>
         
        
      ))}
       
      </div>
     
    </div>
  );
};

export default BlogDetail;

